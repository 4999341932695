import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/database";
import "firebase/functions";
import moment from "moment";
import { v4 } from "uuid";
// import firebaseConfig from '../firebaseConfig';

export const provider = new firebase.auth.GoogleAuthProvider();

//  Eccomi Firebase Config

const firebaseConfig = {
  apiKey: "AIzaSyBtYuXbChxVBlVrdE11haS6JqWVh-S6tv0",
  authDomain: "eccomi-io.firebaseapp.com",
  //authDomain: "unifg.eccomi.io",
  databaseURL: "https://eccomi-io.firebaseio.com",
  projectId: "eccomi-io",
  storageBucket: "eccomi-io.appspot.com",
  messagingSenderId: "842957109987",
  appId: "1:842957109987:web:c91d7fd09e0959de6d23d6",
  measurementId: "G-L0FHPR9C9M",
};

//  Eccomi UI dev Firebase Config

// const firebaseConfig = {
//   apiKey: "AIzaSyDMA8_de3OYkht8HjUMDc-2GIW-gL1zkcc",
//   authDomain: "eccomi-io-dev.firebaseapp.com",
//   databaseURL: "https://eccomi-io-dev-default-rtdb.europe-west1.firebasedatabase.app",
//   //databaseURL: "http://127.0.0.1:9000/?ns=eccomi-io-dev",
//   projectId: "eccomi-io-dev",
//   storageBucket: "eccomi-io-dev.appspot.com",
//   messagingSenderId: "370568604983",
//   appId: "1:370568604983:web:2cc8247939e558e3defeb2",
//   measurementId: "G-5VFFDFRZS5"
// };

firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();
export const database = firebase.database();
export const functions = firebase.functions();

if (window.location.hostname === "localhost") {
  //auth.useEmulator("http://localhost:9099");
  // db.useEmulator("localhost", '8000');
  // database.useEmulator("localhost", '9000');
  // functions.useEmulator('localhost', '5001');
} 

export function databaseGetUsers(onGetUsersCallback) {
  const ref = database.ref("/utenti");
  ref.on("value", onGetUsersCallback);
}

export function databaseGetTFAUsers() {
  return database.ref("/utenti").orderByChild('tfa').equalTo(true).once("value");
}

export function databaseAddDocenteUser(email, codiceFiscale, matricola, tipo, tfa, corsiTfa) {
  // const userId = v4();

  // database.ref("utenti/" + userId).set({
  //   email: email,
  //   codiceFiscale: codiceFiscale,
  //   matricola: matricola,
  //   tipo: tipo
  // });
  return new Promise((resolve, reject) => {
    firebase.auth().createUserWithEmailAndPassword(email, "!DaModificare#2023!")
      .then((userCredential) => {        
        database.ref("utenti/" + userCredential.user.uid).update({
          email: email,
          codiceFiscale: codiceFiscale,
          matricola: matricola,
          tipo: tipo,
          tfa: tfa,
          corsiTfa: corsiTfa
        }).then(() => {
          resolve("OK")
        }).catch((err) => {
          reject(err)
        });
      }).catch((createUserError) => {
        reject(createUserError)
      })
  });  
}

export function databaseAddStudentUser(email, codiceFiscale, matricola, tipo, tfa, corsiTfa) {
  return new Promise((resolve, reject) => {
    firebase.auth().createUserWithEmailAndPassword(email, "!DaModificare#2023!")
      .then((userCredential) => {        
        database.ref("utenti/" + userCredential.user.uid).update({
          email: email,
          codiceFiscale: codiceFiscale,
          matricola: matricola,
          tipo: tipo,
          tfa: tfa,
          corsiTfa: corsiTfa
        }).then(() => {
          resolve("OK")
        }).catch((err) => {
          reject(err)
        });
      }).catch((createUserError) => {
        reject(createUserError)
      })
  });  
}

export function databaseAddUser(email, codiceFiscale, matricola, tipo, tfa, tfaPresenze, gestireAuleSpeciali, corsiTfa, gestirePrenotazioni) {
  return new Promise((resolve, reject) => {
    firebase.auth().createUserWithEmailAndPassword(email, "!DaModificare#2023!")
      .then((userCredential) => {
        database.ref("utenti/" + userCredential.user.uid).update({
          email: email,
          codiceFiscale: codiceFiscale,
          matricola: matricola,
          tipo: tipo,
          tfa: tfa,
          tfaPresenze: tfaPresenze,
          gestireAuleSpeciali: gestireAuleSpeciali,
          corsiTfa: corsiTfa
      }).then(() => {
        resolve("OK")
      }).catch((err) => {
        reject(err)
      });
    }).catch((createUserError) => {
      reject(createUserError)
    })
});  
}

export async function databaseAddUtentiTfaUser(userData) {
  var createOrUpdateUser = functions.httpsCallable('createOrUpdateUser');

  let payload = {
    email: userData.email,
    nome: userData.nome,
    cognome: userData.cognome,
    tipo: userData.tipo,
    codiceFiscale: userData.codiceFiscale,
    impostazioniPrenotazioni: { 
      corsoDiLaurea: userData.corso,
      academicYear: userData.academicYear
    },
    matricola: userData.matricola,
    tfa: userData.tfa,
    isExonerated: userData.isExonerated
  }

  if (userData.isExonerated) {
    payload.exoneratedSubjects = userData.exoneratedSubjects
  }

  return await createOrUpdateUser(payload);
}

export function databaseImportTFAUser(email, nome, cognome, corsoDiLaurea, matricola, codiceFiscale, comuneDiNascita, dataDiNascita) {
  const userId = v4();
  const payload = {
    email: email,
    nome: nome,
    cognome: cognome,
    codiceFiscale: codiceFiscale,
    tipo: "0",
    impostazioniPrenotazioni: { 
      corsoDiLaurea: corsoDiLaurea,
      anno: "1",
      dipartimento: "00001", //ATENEO
    },
    matricola: matricola,
    tfa: true,
  }

  if (dataDiNascita) payload.dataDiNascita = dataDiNascita;
  if (comuneDiNascita) payload.comuneDiNascita = comuneDiNascita;

  console.log('Adding User', payload)

  database.ref("utenti/" + userId).set(payload);
}

export function databaseUpdateUser(id, valuesToUpdate) {
  database.ref("utenti/" + id).update(valuesToUpdate);
}

export function databaseDeleteUser(id, onDeleteUserCallback) {
  database.ref("utenti/" + id).remove(onDeleteUserCallback);
}

export function databaseGetUser(email, onGetUserCallback) {
  database.ref("utenti").orderByChild('email').equalTo(email).once("value", onGetUserCallback);
}

export function databaseGetUserByMatricola(matricola, onGetUserCallback) {
  database.ref("utenti").orderByChild('matricola').equalTo(matricola).once("value", onGetUserCallback);
}

export function databaseGetStudenti(onGetStudentiCallback) {
  database.ref("utenti").orderByChild('tipo').equalTo("0").once("value", onGetStudentiCallback);
}

export function firestoreGetUsers(field, operator, value) {
  if (field && operator && value) {
    return db.collection("utenti").where(field, operator, value).get();
  } else {
    return db.collection("utenti").get();
  }
}

export function firestoreGetStudenti() {
  return db.collection("utenti").where("tipo", "==", "0").get();
}

export function firestoreGetSettings() {
  return db.collection("eccomi_native_app_settings").get();
}

export function firestoreGetLog() {
  return db.collection("eccomi_native_app_log").limit(100).get();
}

export function firestoreGetDipartimenti() {
  return db.collection("dipartimenti").get();
}

export function firestoreGetCorsi() {
  return db.collection("corsi").get();
}

export function firestoreGetCorsiTfa(academicYear) {
  if (academicYear) {
    return db.collection("corsi_tfa")
      .where("academicYear", "==", academicYear)
      .get();
  } else {
    return db.collection("corsi_tfa").get();
  }
}

export function firestoreGetCorsiTfaByCodice(codice) {
  return db.collection("corsi_tfa")
  .where("codice", "==", codice)
  .orderBy('descrizione')
  .get();
}

export function firestoreAmministratoreGetCorsiTfa(corsi, academicYear) {
  console.log("firestoreAmministratoreGetCorsiTfa CORSI", corsi)
  if (corsi[0].toLowerCase() == "tutti") {
    if (academicYear) {
      return db.collection("corsi_tfa")
        .where("academicYear", "==", academicYear)
        .orderBy('descrizione')
        .get();
    } else {
      return db.collection("corsi_tfa")
        .orderBy('descrizione')
        .get();
    }
  } else {
    if (academicYear) {
      return db.collection("corsi_tfa")
        .where("descrizione", "in", corsi)
        .where("academicYear", "==", academicYear)
        .orderBy('descrizione')
        .get();
    } else {
      return db.collection("corsi_tfa")
        .where("descrizione", "in", corsi)
        .orderBy('descrizione')
        .get();
    }
  }
}

export function firestoreGetLezioniTfa(date, academicYear) {
  console.log("firestoreGetLezioniTfa", date, academicYear)
  if (date) {
    if (academicYear) {
      return db.collection("lezioni_tfa")
        .where("academicYear", "==", academicYear)
        .where("orario.data", "==", date)
        .where("isRecoveryLesson", "!=", true)
        .get();
    } else {
      return db.collection("lezioni_tfa")
        .where("orario.data", "==", date)
        .where("isRecoveryLesson", "!=", true)
        .get();
    }
  } else {
    if (academicYear) {
      return db.collection("lezioni_tfa")
        .where("academicYear", "==", academicYear)
        .where("isRecoveryLesson", "!=", true)
        .get();
    } else {
      return db.collection("lezioni_tfa")
        .where("isRecoveryLesson", "!=", true)
        .get();
    }
  }
}

export function firestoreAmministratoreGetLezioniTfa(corsi, academicYear) {
  if (academicYear) {
    return db.collection("lezioni_tfa")
      .where("corso.descrizione", "in", corsi)
      .where("academicYear", "==", academicYear)
      .get();
  } else {
    return db.collection("lezioni_tfa")
      .where("corso.descrizione", "in", corsi)
      .get();
  }
}

export function firestoreGetLezioniDiRecuperoTfa(date, academicYear) {
  if (date) {
    if (academicYear) {
      return db.collection("lezioni_tfa")
        .where("academicYear", "==", academicYear)
        .where("orario.data", "==", date)
        .where("isRecoveryLesson", "==", true)
        .get();
    } else {
      return db.collection("lezioni_tfa")
        .where("orario.data", "==", date)
        .where("isRecoveryLesson", "==", true)
        .get();
    }
  } else {
    if (academicYear) {
      console.log("firestoreGetLezioniTfa academicYear", academicYear)
      return db.collection("lezioni_tfa")
        .where("academicYear", "==", academicYear)
        .where("isRecoveryLesson", "==", true)
        .get();
    } else {
      return db.collection("lezioni_tfa")
        .where("isRecoveryLesson", "==", true)
        .get();
    }
  }
}

export function firestoreAmministratoreGetLezioniDiRecuperoTfa(corsi, academicYear) {
  if (academicYear) {
    return db.collection("lezioni_tfa")
      .where("corso.descrizione", "in", corsi)
      .where("academicYear", "==", academicYear)
      .where("isRecoveryLesson", "==", true)
      .get();
  } else {
    return db.collection("lezioni_tfa")
      .where("corso.descrizione", "in", corsi)
      .where("isRecoveryLesson", "==", true)
      .get();
  }
}

export function firestoreGetOreDiAssenzaLezioni(corso, materia, academicYear) {
  // Do not include recovery lessons. Recovery lessons have multiple courses linked, 
  // so they will be excluded automatically via this condition:
  // where("corso.codice", "==", corso)
  if (corso && materia) {
    return db.collection("lezioni_tfa")
      .where("corso.codice", "==", corso)
      .where("materia", "==", materia)
      .where("academicYear", "==", academicYear)
      .where("orario.data", "<=", moment().format("YYYY-MM-DD"))
      .get();
  } else if (corso) {
    return db.collection("lezioni_tfa")
      .where("corso.codice", "==", corso)
      .where("academicYear", "==", academicYear)
      .where("orario.data", "<=", moment().format("YYYY-MM-DD"))
      .get();
  } else {
    return db.collection("lezioni_tfa")
      .where("academicYear", "==", academicYear)
      .where("orario.data", "<=", moment().format("YYYY-MM-DD"))
      .get();
  }
}

export function firestoreGetTFALessonsByCourseAndDate(course, date) {
  return db.collection("lezioni_tfa")
  .where("corso.codice", "==", course)
  .where("orario.data", "==", date)
  .get();
}

export function firestoreGetTFALessonsByCourse(course) {
  return db.collection("lezioni_tfa")
    .where("corso.codice", "==", course)
    .orderBy("orario.orarioInizioEpoch")
    .get();
}

export function firestoreGetTFALessonsByCourseID(course, academicYear) {
  return db.collection("lezioni_tfa")
    .where("courseIDs", "array-contains", course)
    .where("academicYear", "==", academicYear)
    .orderBy("orario.orarioInizioEpoch")
    .get();
}

export function firestoreGetLezioniTfaByMateria(materia, academicYear) {
  return db.collection("lezioni_tfa")
  .where("materia", "==", materia)
  .where("academicYear", "==", academicYear)
  .get();
}

export function firestoreGetdocentiTfa() {
  return db.collection("docenti_tfa").get();
}

export function firestoreGetMaterieTfa() {
  return db.collection("materie_tfa").orderBy('nome').get();
}

export function firestoreGetAuleTfa() {
  return db.collection("aule_tfa").get();
}

export function firestoreGetAule(aulaDiServizio) {
  if (aulaDiServizio)
    return db.collection("aule").where("aulaDiServizio", "==", true).get();
  else
    return db.collection("aule").get();
}

export function firestoreGetPrenotazioni(codiceDipartimento, data) {
  return db
    .collection("prenotazioni")
    .where("impegno.unitaOrganizzativa.codice", "==", codiceDipartimento)
    .where("impegno.orario.data", "==", data)
    .get();
}

// TFA
export function firestoreGetTfaOreAssenzaPresenze(academicYear, student_surname, subject, course) {
  if (student_surname && subject) {
    return db
      .collection("presenze_tfa")
        .where("academicYear", "==", academicYear)
        .where("materia", "==", subject)
        .where("isRecoveryLesson", "==", false)
        .orderBy('studente.cognome').startAt(student_surname).endAt(student_surname + "\uf8ff")
        .get();
  } else if (student_surname && course) {
    return db
      .collection("presenze_tfa")
        .where("corso.codice", "==", course)
        .where("isRecoveryLesson", "==", false)
        .orderBy('studente.cognome').startAt(student_surname).endAt(student_surname + "\uf8ff")
        .get();
  } else if (subject) {
    return db
      .collection("presenze_tfa")
        .where("materia", "==", subject)
        .where("isRecoveryLesson", "==", false)
        .get();
  } else if (course) {
    return db
      .collection("presenze_tfa")
        .where("corso.codice", "==", course)
        .where("isRecoveryLesson", "!=", true)
        .get();
  } else if (student_surname) {
    return db
      .collection("presenze_tfa")
        .where("isRecoveryLesson", "==", false)
        .orderBy('studente.cognome').startAt(student_surname).endAt(student_surname + "\uf8ff")
        .get();
  } else {
    return db
      .collection("presenze_tfa")
        .where("academicYear", "==", academicYear)
        .where("isRecoveryLesson", "==", false)
        .get();
  }
}

export function firestoreGetRecoveryPresencesForOreDiAssenza(academicYear, studentSurname, subject, course) {
  if (studentSurname && subject) {
    return db
      .collection("presenze_tfa")
        .where("academicYear", "==", academicYear)
        .where("materia", "==", subject)
        .where("isRecoveryLesson", "==", true)
        .orderBy('studente.cognome').startAt(studentSurname).endAt(studentSurname + "\uf8ff")
        .get();
  } else if (studentSurname && course) {
    return db
      .collection("presenze_tfa")
        .where("courseIDs", "array-contains", course)
        .where("isRecoveryLesson", "==", true)
        .orderBy('studente.cognome').startAt(studentSurname).endAt(studentSurname + "\uf8ff")
        .get();
  } else if (subject) {
    return db
      .collection("presenze_tfa")
        .where("materia", "==", subject)
        .where("isRecoveryLesson", "==", true)
        .get();
  } else if (course) {
    return db
      .collection("presenze_tfa")
        .where("courseIDs", "array-contains", course)
        .where("isRecoveryLesson", "==", true)
        .get();
  } else {
    return db
      .collection("presenze_tfa")
        .where("academicYear", "==", academicYear)
        .where("isRecoveryLesson", "==", true)
        .get();
  }
}

export function firestoreGetPresenzaTfaRecovery(cercaCorsoValue, data, annoAccademico) {
  return db
    .collection("presenze_tfa")
    .where("courseIDs", "array-contains", cercaCorsoValue)
    .where("orario.data", "==", data)
    .where("academicYear", "==", annoAccademico)
    .where("isRecoveryLesson", "==", true)
    .orderBy("studente.cognome")
    .get()
}

export function firestoreGetPresenzeTfa(cercaCorsoValue, data, annoAccademico) {
  // Get regular and recovery presences from the presenze_tfa collection and return as a promise.
  return db
    .collection("presenze_tfa")
    .where("corso.codice", "==", cercaCorsoValue)
    .where("orario.data", "==", data)
    .where("academicYear", "==", annoAccademico)
    .orderBy("studente.cognome")
    .get()
}

export function firestoreGetPrenotazioniTracciamentoByCodice(codice, data) {
  return db
    .collection("prenotazioni")
    .where("impegno.codice", "==", codice)
    .where("impegno.orario.data", "==", data)
    .get();
}

export function firestoreGetPrenotazioniTracciamento(email, data) {
  return db
    .collection("prenotazioni")
    .where("studente.email", "==", email)
    .where("impegno.orario.data", "==", data)
    .get();
}

export function firestoreGetPrenotazioniAuleSpeciali(codiceDipartimento, data) {
  return db
    .collection("prenotazioni")
    .where("impegno.unitaOrganizzativa.codice", "==", codiceDipartimento)
    .where("impegno.orario.data", "==", data)
    .where("aulaDiServizio.codiceAula", "!=", "")
    .get();
}

export function firestoreGetPrenotazioniAulaSpeciale(codiceDipartimento, data, codiceAula) {
  return db
    .collection("prenotazioni")
    .where("impegno.unitaOrganizzativa.codice", "==", codiceDipartimento)
    .where("impegno.orario.data", "==", data)
    .where("aulaDiServizio.codiceAula", "==", codiceAula)
    .get();
}

export function firestoreGetAuleSpecialiDisponibili(codiceDipartimento, data) {
  const giornoData = moment(data, 'YYYY-MM-DD').format("dddd")
  
  const giornoMap = {
    Sunday: 'Domenica',
    Monday: 'Lunedì',
    Tuesday: 'Martedì',
    Wednesday: 'Mercoledì',
    Thursday: 'Giovedì',
    Friday: 'Venerdì',
    Saturday: 'Sabato',
  }

  const giornoFinale = giornoMap[giornoData]

  return db
    .collection("aule")
    .where("aulaDiServizio", "==", true)
    .where("codiceDipartimento", "==", codiceDipartimento)
    .where("giorniDisponibili", "array-contains", giornoFinale)
    .get();
}

// export const firebaseGeneratePDFs = async (data) => {
//   var generatePDFsCallable = functions.httpsCallable('generatePDFs');
//   return await generatePDFsCallable(data);
// }

export const firebaseGetZippedPDFsAsync = async (data) => {
  var getZippedPDFsAsyncCallable = functions.httpsCallable('getZippedPDFsAsync');
  return await getZippedPDFsAsyncCallable(data);
}

// Test function.
// export const firebaseSendEmail = async (data) => {
//   var sendEmailCallable = functions.httpsCallable('sendEmail');
//   return await sendEmailCallable(data);
// }

export const firebaseEmailCertificatePDFs = async (data) => {
  var emailCertificatePDFsCallable = functions.httpsCallable('emailCertificatePDFs');
  return await emailCertificatePDFsCallable(data);
}

export const firestoreTFAUpdatePunchOutOnPresence = (presenzaId, punchIns) => {
  return db.collection('presenze_tfa').doc(presenzaId).update({
    punchIns: punchIns
  })
}

export const firestoreTFAGetAllPresencesForStudent = (student_email, course, date) => {
  if (student_email && course && date) {
    return db
      .collection("presenze_tfa")
      .where("studente.email", "==", student_email)
      .where("corso.codice", "==", course)
      .where("orario.data", "==", date)
      .get();
  } else if (student_email && course) {
    return db
      .collection("presenze_tfa")
      .where("studente.email", "==", student_email)
      .where("corso.codice", "==", course)
      .get();
  } else if (student_email && date) {
    return db
      .collection("presenze_tfa")
      .where("studente.email", "==", student_email)
      .where("orario.data", "==", date)
      .get();
  } else if (student_email) {
    return db
      .collection("presenze_tfa")
      .where("studente.email", "==", student_email)
      .get();
  }
}

export const firestoreTFAGetPresencesByLessonData = (lezioneData) => {
  return db
      .collection("presenze_tfa")
      .where("orario.data", "==", lezioneData.orario.data)
      .where("orario.orarioInizio", "==", lezioneData.orario.orarioInizio)
      .where("orario.orarioFine", "==", lezioneData.orario.orarioFine)
      .where("materia", "==", lezioneData.materia)
      .where("aula", "==", lezioneData.aula)
      .orderBy("studente.cognome")
      .get();
}

export const firestoreTFAGetPresencesByStudentEmail = (student_email, academicYear) => {
  return db
      .collection("presenze_tfa")
      .where("studente.email", "==", student_email)
      .where("academicYear", "==", academicYear)
      .orderBy("orario.orarioInizioEpoch")
      .get();
}

export const firestoreTFAGetPresencesByStudentEmailAndDate = (student_email, date, academicYear) => {
  if (!academicYear) {
    return db
        .collection("presenze_tfa")
        .where("studente.email", "==", student_email)
        .where("orario.data", "==", date)
        .orderBy("orario.orarioInizioEpoch")
        .get();
  } else {
    return db
        .collection("presenze_tfa")
        .where("studente.email", "==", student_email)
        .where("orario.data", "==", date)
        .where("academicYear", "==", academicYear)
        .orderBy("orario.orarioInizioEpoch")
        .get();
  }
}


export const firestoreTFAGetPresencesByStudentSurname = (studentEmail, academicYear) => {
  if (academicYear) {
    return db
      .collection("presenze_tfa")
      .where('academicYear', '==', academicYear)
      .where("studente.email", "==", studentEmail)
      .orderBy("studente.cognome")
      .get();
  } else {
    return db
      .collection("presenze_tfa")
      .where("studente.email", "==", studentEmail)
      .orderBy("studente.cognome")
      .get();
  }
}

export const firestoreTFAGetPresencesByStudentSurnameAndDate = (studentEmail, date, academicYear) => {
  if (!academicYear) {
    return db
        .collection("presenze_tfa")
        .where("orario.data", "==", date)
        .where("studente.email", "==", studentEmail)
        .orderBy("studente.cognome")
        .get();
  } else {
    return db
        .collection("presenze_tfa")
        .where("orario.data", "==", date)
        .where("academicYear", "==", academicYear)
        .where("studente.email", "==", studentEmail)
        .orderBy("studente.cognome")
        .get();
  }
}

export const firestoreTFAGetPresencesByStudentEmailAndLesson = (student_email, lesson, academicYear) => {
  console.log('firestoreTFAGetPresencesByStudentEmailAndLesson', 'EMAIL', student_email, "LESSON", lesson, 'ACAD', academicYear)
    return db
      .collection("presenze_tfa")
        .where("orario.data", "==", lesson.orario.data)
        .where("orario.orarioInizio", "==", lesson.orario.orarioInizio)
        .where("orario.orarioFine", "==", lesson.orario.orarioFine)
        .where("materia", "==", lesson.materia)
        .where("aula", "==", lesson.aula)
        .where("studente.email", "==", student_email)
        .where("academicYear", "==", academicYear)
        .orderBy("orario.orarioInizioEpoch")
        .get();

  // if (lesson.isRecoveryLesson) {
  //   if (!academicYear) {
  //     return db
  //       .collection("presenze_tfa")
  //       .where("orario.data", "==", lesson.orario.data)
  //       .where("orario.orarioInizio", "==", lesson.orario.orarioInizio)
  //       .where("orario.orarioFine", "==", lesson.orario.orarioFine)
  //       .where("materia", "==", lesson.materia)
  //       .where("corso.codice", "==", lesson.corso.codice)
  //       .where("studente.email", "==", student_email)
  //       .where("isRecoveryLesson", "==", true)
  //       .orderBy("orario.orarioInizioEpoch")
  //       .get();
  //   } else {
  //     return db
  //       .collection("presenze_tfa")
  //       .where("orario.data", "==", lesson.orario.data)
  //       .where("orario.orarioInizio", "==", lesson.orario.orarioInizio)
  //       .where("orario.orarioFine", "==", lesson.orario.orarioFine)
  //       .where("materia", "==", lesson.materia)
  //       .where("corso.codice", "==", lesson.corso.codice)
  //       .where("studente.email", "==", student_email)
  //       .where("academicYear", "==", academicYear)
  //       .where("isRecoveryLesson", "==", true)
  //       .orderBy("orario.orarioInizioEpoch")
  //       .get();
  //   }
  // } else { // It is not a recovery lesson. Use AULA to match.
  //   if (!academicYear) {
  //     return db
  //       .collection("presenze_tfa")
  //       .where("orario.data", "==", lesson.orario.data)
  //       .where("orario.orarioInizio", "==", lesson.orario.orarioInizio)
  //       .where("orario.orarioFine", "==", lesson.orario.orarioFine)
  //       .where("aula", "==", lesson.aula)
  //       .where("studente.email", "==", student_email)
  //       .where("isRecoveryLesson", "==", false)
  //       .orderBy("orario.orarioInizioEpoch")
  //       .get();
  //   } else {
  //     return db
  //       .collection("presenze_tfa")
  //       .where("orario.data", "==", lesson.orario.data)
  //       .where("orario.orarioInizio", "==", lesson.orario.orarioInizio)
  //       .where("orario.orarioFine", "==", lesson.orario.orarioFine)
  //       .where("aula", "==", lesson.aula)
  //       .where("studente.email", "==", student_email)
  //       .where("academicYear", "==", academicYear)
  //       .where("isRecoveryLesson", "==", false)
  //       .orderBy("orario.orarioInizioEpoch")
  //       .get();
  //   }
  // }
}

export const firebaseUpdatePresencePunchins = (presenceId, punchIns) => {
  console.log('firebaseUpdatePresencePunchins', presenceId, punchIns)
  // return db.
  //   collection("presenze_tfa")
  //   .doc(presenceId)
  //   .update(punchIns)
}

export const firebaseUpdatePresence = (presenceId, payload) => {
  return db.
    collection("presenze_tfa")
    .doc(presenceId)
    .update(payload)
}

export const firebaseAddPresence = (presenceData, callback) => {
   db.
    collection("presenze_tfa")
      .add(presenceData)
      .then(() => {
        console.log("firebaseAddPresence NEW")
        callback()
      })
      .catch((err) => {
        console.log("firebaseAddPresence ERR", err)
      })
}

export const firebaseDeletePresence = (presenceID, callback) => {     
  return db.collection("presenze_tfa")
    .doc(presenceID)
    .delete()
}

export const firestoreTFAGetAcademicYears = () => {
  return db
    .collection("anni_accademici_tfa")
    .orderBy("nome")
    .get();
}

export const firestoreTFAAddAcademicYear = (academicYearData) => {
  return db
    .collection("anni_accademici_tfa")
    .add(academicYearData)
}

export const firestoreTFAModifyAcademicYear = (id, academicYearData) => {
  return db
    .collection("anni_accademici_tfa")
    .doc(id)
    .update(academicYearData)
}

export const firestoreTFADeleteAcademicYear = (id) => {
   return  db.collection("anni_accademici_tfa")
    .doc(id)
    .delete()
}

export const firestoreTFAGetActiveAcademicYear = () => {
  return  db.collection("anni_accademici_tfa")
   .where('attivo', "==", true)
   .get()
}

export const firestoreTFAGetAcademicYearByName = (name) => {
  return  db.collection("anni_accademici_tfa")
   .where('nome', "==", name)
   .get()
}

export const firestoreTFAGetLinkedCourseToAcademicYear = (academicYearName) => {
  return db.collection("corsi_tfa")
   .where('academicYear', "==", academicYearName)
   .limit(1)
   .get()
}

export const firestoreGetTFAPresenceByID = (presenceID) => {
  return db.collection("presenze_tfa")
    .doc(presenceID)
    .get()
}