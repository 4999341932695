import React from 'react'
import { CFooter, CLink } from '@coreui/react'
import { Version } from '../assets/utils/EccomiVersion'

const TheFooter = () => {
  return (
    <CFooter fixed={false}>
      <div>
        <span>
          Ver {
            Version
          }
        </span>
      </div>
    </CFooter>
  )
}

export default React.memo(TheFooter)
