import React, { useContext } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { AuthContext, AuthProvider } from "./auth/AuthProvider";
import PrivateRoute from "./auth/PrivateRoute";
import TheLayout from "./containers/TheLayout";
import "./scss/style.scss";
import UserLogin from "./pages/login/UserLogin";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { ErrorBoundary } from "./errors/ErrorBoundary";

function RoleBasedPrivateRoutes() {
  const { currentRole } = useContext(AuthContext);

  if (currentRole) {
    switch (currentRole) {
      case "Studente":
        return (
          <>
            <PrivateRoute
              exact
              path="/nuovaprenotazione"
              component={TheLayout}
            />
            <PrivateRoute exact path="/prenotazioni" component={TheLayout} />
            <PrivateRoute exact path="/profilo" component={TheLayout} />
          </>
        );
      case "Docente":
        return (
          <>
            <PrivateRoute
              exact
              path="/prenotazioniricevute"
              component={TheLayout}
            />
            <PrivateRoute exact path="/profilo" component={TheLayout} />
            <PrivateRoute exact path="/presenze_tfa" component={TheLayout} />
          </>
        );
      case "Amministratore":
        return (
          <>
            <PrivateRoute
              exact
              path="/prenotazioniricevute"
              component={TheLayout}
            />
            <PrivateRoute
              exact
              path="/prenotazioniaule"
              component={TheLayout}
            />
            <PrivateRoute exact path="/presenze" component={TheLayout} />
            <PrivateRoute exact path="/presenzeaule" component={TheLayout} />
            <PrivateRoute exact path="/profilo" component={TheLayout} />
            <PrivateRoute exact path="/datistatistici" component={TheLayout} />
            <PrivateRoute exact path="/aule-di-servizio" component={TheLayout} />

            {
              // TFA Enable Routes
            }

            <PrivateRoute exact path="/tfa_lezioni" component={TheLayout} />
            <PrivateRoute exact path="/tfa_lezioni_di_recupero" component={TheLayout} />
            <PrivateRoute exact path="/tfa_docenti" component={TheLayout} />
            <PrivateRoute exact path="/materie_tfa" component={TheLayout} />
            <PrivateRoute exact path="/presenze_tfa" component={TheLayout} />
            <PrivateRoute exact path="/aule_tfa" component={TheLayout} />
            <PrivateRoute exact path="/tfa_ore_di_assenza" component={TheLayout} />
            <PrivateRoute exact path="/corsi_tfa" component={TheLayout} />
            <PrivateRoute exact path="/tfa_anni_accademici" component={TheLayout} />

            {/* Below mentioned routes are only for super admin.
                They are here for testing purpose only.
            */}
            {/* <PrivateRoute exact path="/dipartimenti" component={TheLayout} />
            <PrivateRoute exact path="/corsi" component={TheLayout} /> */}
          </>
        );
      case "SuperAdmin":
        return (
          <>
            <PrivateRoute exact path="/profilo" component={TheLayout} />
            <PrivateRoute
              exact
              path="/prenotazioniricevute"
              component={TheLayout}
            />
            <PrivateRoute
              exact
              path="/prenotazioniaule"
              component={TheLayout}
            />
            <PrivateRoute exact path="/presenze" component={TheLayout} />
            <PrivateRoute exact path="/presenzeaule" component={TheLayout} />
            <PrivateRoute exact path="/impostazioni" component={TheLayout} />
            <PrivateRoute exact path="/log" component={TheLayout} />
            <PrivateRoute exact path="/dipartimenti" component={TheLayout} />
            <PrivateRoute exact path="/corsi" component={TheLayout} />
            <PrivateRoute exact path="/aule" component={TheLayout} />
            <PrivateRoute exact path="/corsi_tfa" component={TheLayout} />
            <PrivateRoute exact path="/utenti_tfa" component={TheLayout} />
            <PrivateRoute
              exact
              path="/aule-di-servizio"
              component={TheLayout}
            />
            <PrivateRoute exact path="/datistatistici" component={TheLayout} />
            <PrivateRoute exact path="/utenti" component={TheLayout} />
            <PrivateRoute exact path="/tfa_lezioni" component={TheLayout} />
            <PrivateRoute exact path="/tfa_lezioni_di_recupero" component={TheLayout} />
            <PrivateRoute exact path="/tfa_docenti" component={TheLayout} />
            <PrivateRoute exact path="/presenze_tfa" component={TheLayout} />
            <PrivateRoute exact path="/materie_tfa" component={TheLayout} />
            <PrivateRoute exact path="/aule_tfa" component={TheLayout} />
            <PrivateRoute exact path="/tfa_ore_di_assenza" component={TheLayout} />
            <PrivateRoute exact path="/tfa_anni_accademici" component={TheLayout} />
            <PrivateRoute exact path="/tracciamento_studente" component={TheLayout} />
          </>
        );
      default:
        return <p>Attendere prego...</p>;
    }
  } else {
    return null;
  }
}

export default function App() {
  Sentry.init({
    dsn: "https://a200020908584656af201817edbb6472@o460603.ingest.sentry.io/5535289",
    integrations: [new Integrations.BrowserTracing()],
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception) {
        Sentry.showReportDialog({ eventId: event.event_id });
      }
      return event;
    },

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });

  return (
    <ErrorBoundary>
      <AuthProvider>
        <BrowserRouter>
          <>
            <RoleBasedPrivateRoutes />
            <Route exact path="/" component={UserLogin} />
          </>
        </BrowserRouter>
      </AuthProvider>
    </ErrorBoundary>
  );
}
