export default [
  // {
  //   _tag: 'CSidebarNavTitle',
  //   _children: ['Profilo Docente']
  // },
  // {
  //   _tag: 'CSidebarNavTitle',
  //   _children: ['Prenotazioni']
  // },
  {
    _tag: 'CSidebarNavItem',
    name: 'Elenco Prenotazioni Ricevute',
    to: '/prenotazioniricevute',
    icon: 'cil-notes',
  },
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Impostazioni']
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Profilo',
    to: '/profilo',
    icon: 'cil-user',
  },
]