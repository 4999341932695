import React from 'react'

const Prenotazioni = React.lazy(() => import('./views/prenotazioni/Prenotazioni'))
const Profilo = React.lazy(() => import('./views/profilo/Profilo'))
const NuovaPrenotazione = React.lazy(() => import('./views/prenotazioni/NuovaPrenotazione'))
const DocentePrenotazioni = React.lazy(() => import('./views/prenotazioni/DocentePrenotazioni'))
const PrenotazioniAuleSpeciali = React.lazy(() => import('./views/prenotazioni/PrenotazioniAuleSpeciali'))
const Presenze = React.lazy(() => import('./views/prenotazioni/Presenze'))
const PresenzeAuleSpeciali = React.lazy(() => import('./views/prenotazioni/PresenzeAuleSpeciali'))
const Utenti = React.lazy(() => import('./views/impostazioni/Utenti'))
const Impostazioni = React.lazy(() => import('./views/impostazioni/Impostazioni'))
const Studenti = React.lazy(() => import('./views/prenotazioni/Studenti'))
const Dipartimenti = React.lazy(() => import('./views/impostazioni/Dipartimenti'))
const Corsi = React.lazy(() => import('./views/impostazioni/Corsi'))
const Aule = React.lazy(() => import('./views/impostazioni/Aule'))
const AuleDiServizio = React.lazy(() => import('./views/impostazioni/AuleDiServizio'))
const DatiStatistici = React.lazy(() => import('./views/DatiStatistici/DatiStatistici'))
const Log = React.lazy(() => import('./views/impostazioni/Log'))
const TFALezioni = React.lazy(() => import('./views/impostazioni/TFALezioni'))
const TFADocenti = React.lazy(() => import('./views/impostazioni/TFADocenti'))
const CorsiTFA = React.lazy(() => import('./views/impostazioni/CorsiTFA'))
const UtentiTFA = React.lazy(() => import('./views/impostazioni/UtentiTFA'))
const PresenzeTFA = React.lazy(() => import('./views/prenotazioni/PresenzeTfa'))
const MaterieTFA = React.lazy(() => import('./views/impostazioni/MaterieTFA'))
const AuleTFA = React.lazy(() => import('./views/impostazioni/AuleTFA'))
const TracciamentoStudente = React.lazy(() => import('./views/TracciamentoStudente/index'))
const TFAOreDiAssenza = React.lazy(() => import('./views/TFAOreDiAssenza/index'))
const TFAAcademicYear = React.lazy(() => import('./views/impostazioni/TFAAcademicYear'))
const TFALezioniDiRecupero = React.lazy(() => import('./views/impostazioni/TFALezioniDiRecupero'))

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config

const routes = [
  { path: '/prenotazioni', name: 'Prenotazioni', component: Prenotazioni },
  { path: '/profilo', name: 'Profilo', component: Profilo },
  { path: '/nuovaprenotazione', name: 'Nuova Prenotazione', component: NuovaPrenotazione },
  { path: '/prenotazioniricevute', name: 'Prenotazioni Ricevute', component: DocentePrenotazioni },
  { path: '/prenotazioniaule', name: 'Prenotazioni Aule Serv.', component: PrenotazioniAuleSpeciali },
  { path: '/presenze', name: 'Presenze', component: Presenze },
  { path: '/presenzeaule', name: 'Presenze Aule Serv.', component: PresenzeAuleSpeciali },
  { path: '/utenti', name: 'Utenti', component: Utenti },
  { path: '/impostazioni', name: 'Impostazioni', component: Impostazioni },
  { path: '/log', name: 'Becon Log', component: Log },
  { path: '/studenti', name: 'Studenti', component: Studenti },
  { path: '/dipartimenti', name: 'Dipartimenti', component: Dipartimenti },
  { path: '/corsi', name: 'Corsi', component: Corsi },
  { path: '/aule', name: 'Aule', component: Aule },
  { path: '/aule-di-servizio', name: 'Aule Di Servizio', component: AuleDiServizio },
  { path: '/datistatistici', name: 'Dati Statistici', component:  DatiStatistici},
  { path: '/tfa_lezioni', name: 'TFA Lezioni', component: TFALezioni},
  { path: '/tfa_docenti', name: 'TFA Docenti', component: TFADocenti},
  { path: '/corsi_tfa', name: 'TFA Corsi', component: CorsiTFA},
  { path: '/utenti_tfa', name: 'TFA Utenti', component: UtentiTFA},
  { path: '/presenze_tfa', name: 'TFA Presenze', component: PresenzeTFA},
  { path: '/materie_tfa', name: 'TFA Materie', component: MaterieTFA},
  { path: '/aule_tfa', name: 'TFA Aule', component: AuleTFA},
  { path: '/tfa_ore_di_assenza', name: 'TFA Ore Di Assenza', component: TFAOreDiAssenza},
  { path: '/tracciamento_studente', name: 'Tracciamento Studente', component: TracciamentoStudente},
  { path: '/tfa_anni_accademici', name: 'TFA Anni Accademici', component: TFAAcademicYear},
  { path: '/tfa_lezioni_di_recupero', name: 'TFA Lezioni di Recupero', component: TFALezioniDiRecupero},
]

export default routes
