import {
  cilApplicationsSettings,
  cilSpeedometer,
  cilUser,
  cilNotes,
  cilSearch,
  cilPlus,
  cilDelete,
  cilReload,
  cilSave,
  cilAccountLogout,
  cilPrint,
  cilFile,
  cilCalendar,
  cilClock,
  cilSchool,
  cilBuilding,
  cilPeople,
  cilSettings,
  cilUserPlus
} from '@coreui/icons'

export const icons = Object.assign({}, {
}, {
  cilApplicationsSettings,
  cilSpeedometer,
  cilUser,
  cilNotes,
  cilSearch,
  cilPlus,
  cilDelete,
  cilReload,
  cilSave,
  cilAccountLogout,
  cilPrint,
  cilFile,
  cilCalendar,
  cilClock,
  cilSchool,
  cilBuilding,
  cilPeople,
  cilSettings,
  cilUserPlus
})
