export default [
    {
      _tag: 'CSidebarNavTitle',
      _children: ['Profilo SuperAdmin']
    },
    {
      _tag: 'CSidebarNavItem',
      name: 'Profilo',
      to: '/profilo',
      icon: 'cil-user',
    },
    {
      _tag: 'CSidebarNavTitle',
      _children: ['Prenotazioni']
    },
    {
      _tag: 'CSidebarNavItem',
      name: 'Elenco Prenotazioni Ricevute',
      to: '/prenotazioniricevute',
      icon: 'cil-notes',
    },
    {
      _tag: 'CSidebarNavItem',
      name: 'Elenco Prenotazioni Aule Serv.',
      to: '/prenotazioniaule',
      icon: 'cil-notes',
    },
    {
      _tag: 'CSidebarNavItem',
      name: 'Presenze',
      to: '/presenze',
      icon: 'cil-notes',
    },
    {
      _tag: 'CSidebarNavItem',
      name: 'Presenze Aule Serv.',
      to: '/presenzeaule',
      icon: 'cil-notes',
    },
    {
      _tag: 'CSidebarNavItem',
      name: 'Dati Statistici',
      to: '/datistatistici',
      icon: 'cil-notes',
    },
    {
      _tag: 'CSidebarNavItem',
      name: 'Tracciamento Studente',
      to: '/tracciamento_studente',
      icon: 'cil-notes',
    },
    {
      _tag: 'CSidebarNavTitle',
      _children: ['TFA']
    },
    {
      _tag: 'CSidebarNavItem',
      name: 'TFA Anni Accademici',
      to: '/tfa_anni_accademici',
      icon: 'cil-file',
    },
    {
      _tag: 'CSidebarNavItem',
      name: 'TFA Docenti',
      to: '/tfa_docenti',
      icon: 'cil-file',
    },
    {
      _tag: 'CSidebarNavItem',
      name: 'TFA Materie',
      to: '/materie_tfa',
      icon: 'cil-file',
    },
    {
      _tag: 'CSidebarNavItem',
      name: 'TFA Lezioni',
      to: '/tfa_lezioni',
      icon: 'cil-file',
    },
    {
      _tag: 'CSidebarNavItem',
      name: 'TFA Lezioni di Recupero',
      to: '/tfa_lezioni_di_recupero',
      icon: 'cil-file',
    },
    {
      _tag: 'CSidebarNavItem',
      name: 'TFA Corsi',
      to: '/corsi_tfa',
      icon: 'cil-file',
    },
    {
      _tag: 'CSidebarNavItem',
      name: 'TFA Aule',
      to: '/aule_tfa',
      icon: 'cil-file',
    },
    {
      _tag: 'CSidebarNavItem',
      name: 'TFA Utenti',
      to: '/utenti_tfa',
      icon: 'cil-file',
    },
    {
      _tag: 'CSidebarNavItem',
      name: 'TFA Presenze',
      to: '/presenze_tfa',
      icon: 'cil-file',
    },
    {
      _tag: 'CSidebarNavItem',
      name: 'TFA Ore Di Assenza',
      to: '/tfa_ore_di_assenza',
      icon: 'cil-file',
    },
    {
      _tag: 'CSidebarNavTitle',
      _children: ['Impostazioni']
    },
    {
      _tag: 'CSidebarNavItem',
      name: 'Dipartimenti',
      to: '/dipartimenti',
      icon: 'cil-file',
    },
    {
      _tag: 'CSidebarNavItem',
      name: 'Corsi',
      to: '/corsi',
      icon: 'cil-file',
    },
    {
      _tag: 'CSidebarNavItem',
      name: 'Aule',
      to: '/aule',
      icon: 'cil-file',
    },
    {
      _tag: 'CSidebarNavItem',
      name: 'Aule di Servizio',
      to: '/aule-di-servizio',
      icon: 'cil-file',
    },
    {
      _tag: 'CSidebarNavItem',
      name: 'Utenti',
      to: '/utenti',
      icon: 'cil-user',
    },
    {
      _tag: 'CSidebarNavItem',
      name: 'Impostazioni',
      to: '/impostazioni',
      icon: 'cil-settings',
    },
    {
      _tag: 'CSidebarNavItem',
      name: 'Log',
      to: '/log',
      icon: 'cil-file',
    },
  ]