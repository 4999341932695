export default [
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Profilo Amministratore']
  },
  {
    _tag: 'CSidebarNavTitle',
    _children: ['Impostazioni']
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'Profilo',
    to: '/profilo',
    icon: 'cil-user',
  },
]